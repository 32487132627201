import { PeriodType } from '@amzn/claritygqllambda';
import {
  DurationType,
  TemporalAdjuster,
  TimespanType,
} from '@clarity-website/reports/filters/timespan-config';

export type Item = AttributeInterface | MetricsInterface | TimespanInterface;

export interface ValueInterface {
  id: string;
  value: string;
  parentId: string;
}

export function isChildValue(
  item: Item | ValueInterface,
): item is ValueInterface {
  return (item as ValueInterface).value !== undefined;
}

export function isReportFilterId(id: string): boolean {
  return id.split(REPORT_FILTER_SEPARATOR).length === 3;
}

export const REPORT_FILTER_SEPARATOR = 'ヽ(°〇°)ﾉ';

export const LEADER_HIERARCHY_FILTER_ID = 'LeaderSelectHierarchyFilter';

export interface AttributeInterface {
  isMultiselect?: boolean;
  attributeGroup: string;
  parentId?: string;
  id: string;
  valueType: string;
  sqlColumn?: string;
  derivedSql?: string;
  values: string[];
  name: string;
  description: string;
  cardinality: number;
  attributeSubGroups?: string[];
  [key: string]: string | boolean | string[] | undefined | number;
  classification?: string;
  tags?: string[];
}

export interface MetricsInterface {
  isMultiselect?: boolean;
  parentId?: string;
  id: string;
  name: string;
  description?: string;
  valueType: string;
  precision?: number;
  ownedBy: string;
  calculation: string;
  datasourceIds: string[];
  subcategories?: string[];
  metricColumns?: string[];
  [key: string]: string | string[] | boolean | number | undefined;
  classification?: string;
  tags?: string[];
  intradaySql?: string;
  dailySql?: string;
  weeklySql?: string;
  monthlySql?: string;
  quarterlySql?: string;
  yearlySql?: string;
  ttmSql?: string;
  t3mSql?: string;
  t6mSql?: string;
  ytdSql?: string;
  pySql?: string;
  pmSql?: string;
  pqSql?: string;
}

export interface ChildFilterInterface {
  attributeId: string;
  operator: string;
  values: string[];
}

export interface TimespanInterface {
  id: string;
  name: string;
  parent?: string;
  parentId?: string;
  description?: string;
  type: TimespanType;
  expression: string;
  periodType: PeriodType;
  durationType: DurationType;
  duration: number;
  domains: string[];
  temporalAdjusters: TemporalAdjuster[];
  isSparkchartActive?: boolean;
}

export type MetadataInterface =
  | AttributeInterface
  | MetricsInterface
  | TimespanInterface;

import { Domain, PeriodType, TimespanComparison } from '@amzn/claritygqllambda';

export interface TimespanInterface {
  id: string;
  name: string;
  parentId?: string;
  description?: string;
  type: TimespanType;
  expression: string;
  periodType: PeriodType;
  durationType: DurationType;
  duration: number;
  domains: string[];
  temporalAdjusters: TemporalAdjuster[];
}

export interface TimespanSchema {
  type: TimespanType;
  expression: string;
  periodType: PeriodType;
  durationType: DurationType;
  duration: number;
  temporalAdjusters: TemporalAdjuster[];
  comparisons?: TimespanComparison[];
  isSparkchartActive?: boolean;
}

export interface TemporalAdjuster {
  arguments: string[];
  function: TemporalAdjusterFunction;
  __typename?: string;
}

export enum TemporalAdjusterFunction {
  identity = 'identity',
  adjustTimezone = 'adjustTimezone',
  startOfHour = 'startOfHour',
  startOfDay = 'startOfDay',
  startOfSundayWeek = 'startOfSundayWeek',
  startOfSundayWeekWithNowWeekNumber = 'startOfSundayWeekWithNowWeekNumber',
  startOfIsoWeek = 'startOfIsoWeek',
  startOfIsoWeekWithNowWeekNumber = 'startOfIsoWeekWithNowWeekNumber',
  startOfMonth = 'startOfMonth',
  startOfQuarter = 'startOfQuarter',
  startOfYear = 'startOfYear',
  startOfPerfYear = 'startOfPerfYear',
  endOfHour = 'endOfHour',
  endOfDay = 'endOfDay',
  endOfSundayWeek = 'endOfSundayWeek',
  endOfIsoWeek = 'endOfIsoWeek',
  endOfMonth = 'endOfMonth',
  endOfQuarter = 'endOfQuarter',
  endOfYear = 'endOfYear',
  minusHours = 'minusHours',
  minusDays = 'minusDays',
  minusWeeks = 'minusWeeks',
  minusMonths = 'minusMonths',
  minusQuarters = 'minusQuarters',
  minusYears = 'minusYears',
  plusHours = 'plusHours',
  plusDays = 'plusDays',
  plusWeeks = 'plusWeeks',
  plusMonths = 'plusMonths',
  plusQuarters = 'plusQuarters',
  plusYears = 'plusYears',
  maxAbsTimespan = 'maxAbsTimespan',
}

export enum TIMESPAN_PREFIXES {
  i = 'i',
  d = 'd',
  w = 'w',
  m = 'm',
  pm = 'pm',
  q = 'q',
  y = 'y',
  py = 'py',
  all = 'all',
}

export enum DurationType {
  Hour = 'Hour',
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Quarter = 'Quarter',
  Year = 'Year',
  Ttm = 'Ttm',
  T3m = 'T3m',
  T6m = 'T6m',
  Ytd = 'Ytd',
  Py = 'Py',
  Pm = 'Pm',
  Pq = 'Pq',
}

export enum TimespanType {
  Current_Period = 'Current_Period',
  Cron_Sequence = 'Cron_Sequence',
}

export const extractIdFromTimespan = ({
  type,
  expression,
  periodType,
  durationType,
  duration,
  temporalAdjusters,
  comparisons,
}: TimespanSchema): string => {
  const temporalAdjusterKey = temporalAdjusters
    ?.map((adjuster) => [adjuster.function, adjuster.arguments].join('^'))
    .join('#');

  const timespanComparisons = comparisons
    ?.map((tComp) => [tComp.comparison, tComp.comparisonType].join('^'))
    .join('#');

  return [
    type,
    expression,
    periodType,
    durationType,
    duration,
    temporalAdjusterKey,
    timespanComparisons,
  ]
    .filter(Boolean)
    .join(':');
};

export const timespanConfigs: TimespanInterface[] = [
  {
    id: '',
    name: 'Current Hour',
    duration: 1,
    durationType: DurationType.Hour,
    expression: '0 0 * * * ? *',
    periodType: PeriodType.Intraday,
    temporalAdjusters: [
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfHour,
      },
    ],
    type: TimespanType.Current_Period,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Last 1 Hours',
    duration: 1,
    durationType: DurationType.Hour,
    expression: '0 0 * * * ? *',
    periodType: PeriodType.Intraday,
    temporalAdjusters: [
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusHours,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfHour,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Last 4 Hours',
    duration: 4,
    durationType: DurationType.Hour,
    expression: '0 0 * * * ? *',
    periodType: PeriodType.Intraday,
    temporalAdjusters: [
      {
        arguments: ['4'],
        function: TemporalAdjusterFunction.minusHours,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfHour,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location, Domain.TestSeattle],
  },
  {
    id: '',
    name: 'Last 8 Hours',
    duration: 8,
    durationType: DurationType.Hour,
    expression: '0 0 * * * ? *',
    periodType: PeriodType.Intraday,
    temporalAdjusters: [
      {
        arguments: ['8'],
        function: TemporalAdjusterFunction.minusHours,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfHour,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Last 12 Hours',
    duration: 12,
    durationType: DurationType.Hour,
    expression: '0 0 * * * ? *',
    periodType: PeriodType.Intraday,
    temporalAdjusters: [
      {
        arguments: ['12'],
        function: TemporalAdjusterFunction.minusHours,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfHour,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location, Domain.TestSeattle],
  },
  {
    id: '',
    name: 'Current Day',
    duration: 1,
    durationType: DurationType.Day,
    expression: '0 0 0 * * ? *',
    periodType: PeriodType.Daily,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfDay,
      },
    ],
    type: TimespanType.Current_Period,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Last 1 Days',
    duration: 1,
    durationType: DurationType.Day,
    expression: '0 0 0 * * ? *',
    periodType: PeriodType.Daily,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusDays,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfDay,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location, Domain.TestSeattle],
  },
  {
    id: '',
    name: 'Last 4 Days',
    duration: 4,
    durationType: DurationType.Day,
    expression: '0 0 0 * * ? *',
    periodType: PeriodType.Daily,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['4'],
        function: TemporalAdjusterFunction.minusDays,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfDay,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Last 6 Days',
    duration: 6,
    durationType: DurationType.Day,
    expression: '0 0 0 * * ? *',
    periodType: PeriodType.Daily,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['6'],
        function: TemporalAdjusterFunction.minusDays,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfDay,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Last 8 Days',
    duration: 8,
    durationType: DurationType.Day,
    expression: '0 0 0 * * ? *',
    periodType: PeriodType.Daily,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['8'],
        function: TemporalAdjusterFunction.minusDays,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfDay,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location, Domain.TestSeattle],
  },
  {
    id: '',
    name: 'Last 21 Days',
    duration: 21,
    durationType: DurationType.Day,
    expression: '0 0 0 * * ? *',
    periodType: PeriodType.Daily,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['21'],
        function: TemporalAdjusterFunction.minusDays,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfDay,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Current Calendar Week',
    duration: 7,
    durationType: DurationType.Day,
    expression: '0 0 0 * * ? *',
    periodType: PeriodType.Daily,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfSundayWeek,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Last Calendar Week',
    duration: 7,
    durationType: DurationType.Day,
    expression: '0 0 0 * * ? *',
    periodType: PeriodType.Daily,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusWeeks,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfSundayWeek,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Last 7 Days as of prior week',
    duration: 7,
    durationType: DurationType.Day,
    expression: '0 0 0 * * ? *',
    periodType: PeriodType.Daily,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusWeeks,
      },
      {
        arguments: ['7'],
        function: TemporalAdjusterFunction.minusDays,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfDay,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Last 1 Days as of prior year',
    duration: 1,
    durationType: DurationType.Day,
    expression: '0 0 0 * * ? *',
    periodType: PeriodType.Daily,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusYears,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusDays,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfDay,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Last 4 Days as of prior year',
    duration: 4,
    durationType: DurationType.Day,
    expression: '0 0 0 * * ? *',
    periodType: PeriodType.Daily,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusYears,
      },
      {
        arguments: ['4'],
        function: TemporalAdjusterFunction.minusDays,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfDay,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Last 8 Days as of prior year',
    duration: 8,
    durationType: DurationType.Day,
    expression: '0 0 0 * * ? *',
    periodType: PeriodType.Daily,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusYears,
      },
      {
        arguments: ['8'],
        function: TemporalAdjusterFunction.minusDays,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfDay,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Next 2 Weeks',
    duration: 2,
    durationType: DurationType.Week,
    expression: '0 0 0 ? * SUN *',
    periodType: PeriodType.Weekly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfSundayWeek,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.plusWeeks,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Current WTD',
    duration: 1,
    durationType: DurationType.Week,
    expression: '0 0 0 ? * SUN *',
    periodType: PeriodType.Weekly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfSundayWeek,
      },
    ],
    type: TimespanType.Current_Period,
    domains: [Domain.Location, Domain.TestDistrictOfColumbia],
  },
  {
    id: '',
    name: 'Last 1 Weeks',
    duration: 1,
    durationType: DurationType.Week,
    expression: '0 0 0 ? * SUN *',
    periodType: PeriodType.Weekly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusWeeks,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfSundayWeek,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Last 4 Weeks',
    duration: 4,
    durationType: DurationType.Week,
    expression: '0 0 0 ? * SUN *',
    periodType: PeriodType.Weekly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfSundayWeekWithNowWeekNumber,
      },
      {
        arguments: ['4'],
        function: TemporalAdjusterFunction.minusWeeks,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfSundayWeek,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Last 6 Weeks',
    duration: 6,
    durationType: DurationType.Week,
    expression: '0 0 0 ? * SUN *',
    periodType: PeriodType.Weekly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['6'],
        function: TemporalAdjusterFunction.minusWeeks,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfSundayWeek,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Last 1 Weeks as of prior year',
    duration: 1,
    durationType: DurationType.Week,
    expression: '0 0 0 ? * SUN *',
    periodType: PeriodType.Weekly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusYears,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfSundayWeekWithNowWeekNumber,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusWeeks,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Prior Year, Last 4 Weeks',
    duration: 4,
    durationType: DurationType.Week,
    expression: '0 0 0 ? * SUN *',
    periodType: PeriodType.Weekly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusYears,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfSundayWeekWithNowWeekNumber,
      },
      {
        arguments: ['4'],
        function: TemporalAdjusterFunction.minusWeeks,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Prior Year, Last 6 Weeks',
    duration: 6,
    durationType: DurationType.Week,
    expression: '0 0 0 ? * SUN *',
    periodType: PeriodType.Weekly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusYears,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfSundayWeekWithNowWeekNumber,
      },
      {
        arguments: ['6'],
        function: TemporalAdjusterFunction.minusWeeks,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location, Domain.TestDistrictOfColumbia],
  },
  {
    id: '',
    name: 'Current MTD',
    duration: 1,
    durationType: DurationType.Month,
    expression: '0 0 0 1 * ? *',
    periodType: PeriodType.Monthly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfMonth,
      },
    ],
    type: TimespanType.Current_Period,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Last 1 Months',
    duration: 1,
    durationType: DurationType.Month,
    expression: '0 0 0 1 * ? *',
    periodType: PeriodType.Monthly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusMonths,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfMonth,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location, Domain.Organization, Domain.OrganizationRow],
  },
  {
    id: '',
    name: 'Last 2 Months',
    duration: 1,
    durationType: DurationType.Month,
    expression: '0 0 0 1 * ? *',
    periodType: PeriodType.Monthly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['2'],
        function: TemporalAdjusterFunction.minusMonths,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfMonth,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Organization, Domain.OrganizationRow],
  },
  {
    id: '',
    name: 'Last 3 Months',
    duration: 1,
    durationType: DurationType.Month,
    expression: '0 0 0 1 * ? *',
    periodType: PeriodType.Monthly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['3'],
        function: TemporalAdjusterFunction.minusMonths,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfMonth,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Organization, Domain.OrganizationRow],
  },
  {
    id: '',
    name: 'Last 4 Months',
    duration: 4,
    durationType: DurationType.Month,
    expression: '0 0 0 1 * ? *',
    periodType: PeriodType.Monthly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['4'],
        function: TemporalAdjusterFunction.minusMonths,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfMonth,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Organization, Domain.OrganizationRow],
  },
  {
    id: '',
    name: 'Last 5 Months',
    duration: 1,
    durationType: DurationType.Month,
    expression: '0 0 0 1 * ? *',
    periodType: PeriodType.Monthly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['5'],
        function: TemporalAdjusterFunction.minusMonths,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfMonth,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Organization, Domain.OrganizationRow],
  },
  {
    id: '',
    name: 'Last 6 Months',
    duration: 1,
    durationType: DurationType.Month,
    expression: '0 0 0 1 * ? *',
    periodType: PeriodType.Monthly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['6'],
        function: TemporalAdjusterFunction.minusMonths,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfMonth,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Organization, Domain.OrganizationRow],
  },
  {
    id: '',
    name: 'Last 3 Months',
    duration: 3,
    durationType: DurationType.Month,
    expression: '0 0 0 1 * ? *',
    periodType: PeriodType.Monthly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['3'],
        function: TemporalAdjusterFunction.minusMonths,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfMonth,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location, Domain.TestDistrictOfColumbia],
  },
  {
    id: '',
    name: 'Last 1 Months as of prior year',
    duration: 1,
    durationType: DurationType.Month,
    expression: '0 0 0 1 * ? *',
    periodType: PeriodType.Monthly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusYears,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusMonths,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfMonth,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Last 3 Months as of prior year',
    duration: 3,
    durationType: DurationType.Month,
    expression: '0 0 0 1 * ? *',
    periodType: PeriodType.Monthly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusYears,
      },
      {
        arguments: ['3'],
        function: TemporalAdjusterFunction.minusMonths,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfMonth,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Current QTD',
    duration: 1,
    durationType: DurationType.Quarter,
    expression: '0 0 0 1 */3 ? *',
    periodType: PeriodType.Quarterly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfQuarter,
      },
    ],
    type: TimespanType.Current_Period,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Last 1 Quarters',
    duration: 1,
    durationType: DurationType.Quarter,
    expression: '0 0 0 1 */3 ? *',
    periodType: PeriodType.Quarterly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusQuarters,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfQuarter,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location, Domain.Organization],
  },
  {
    id: '',
    name: 'Last 2 Quarters',
    duration: 1,
    durationType: DurationType.Quarter,
    expression: '0 0 0 1 */3 ? *',
    periodType: PeriodType.Quarterly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['2'],
        function: TemporalAdjusterFunction.minusQuarters,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfQuarter,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Organization],
  },
  {
    id: '',
    name: 'Last 3 Quarters',
    duration: 1,
    durationType: DurationType.Quarter,
    expression: '0 0 0 1 */3 ? *',
    periodType: PeriodType.Quarterly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['3'],
        function: TemporalAdjusterFunction.minusQuarters,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfQuarter,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Organization],
  },
  {
    id: '',
    name: 'Last 4 Quarters',
    duration: 1,
    durationType: DurationType.Quarter,
    expression: '0 0 0 1 */3 ? *',
    periodType: PeriodType.Quarterly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['4'],
        function: TemporalAdjusterFunction.minusQuarters,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfQuarter,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Organization],
  },
  {
    id: '',
    name: 'Last 5 Quarters',
    duration: 1,
    durationType: DurationType.Quarter,
    expression: '0 0 0 1 */3 ? *',
    periodType: PeriodType.Quarterly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['5'],
        function: TemporalAdjusterFunction.minusQuarters,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfQuarter,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Organization],
  },
  {
    id: '',
    name: 'Last 6 Quarters',
    duration: 1,
    durationType: DurationType.Quarter,
    expression: '0 0 0 1 */3 ? *',
    periodType: PeriodType.Quarterly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['6'],
        function: TemporalAdjusterFunction.minusQuarters,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfQuarter,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Organization],
  },
  {
    id: '',
    name: 'Last 3 Quarters',
    duration: 3,
    durationType: DurationType.Quarter,
    expression: '0 0 0 1 */3 ? *',
    periodType: PeriodType.Quarterly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['3'],
        function: TemporalAdjusterFunction.minusQuarters,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfQuarter,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Last 4 Quarters',
    duration: 4,
    durationType: DurationType.Quarter,
    expression: '0 0 0 1 */3 ? *',
    periodType: PeriodType.Quarterly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['4'],
        function: TemporalAdjusterFunction.minusQuarters,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfQuarter,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Last 1 Quarters as of prior year',
    duration: 1,
    durationType: DurationType.Quarter,
    expression: '0 0 0 1 */3 ? *',
    periodType: PeriodType.Quarterly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusYears,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusQuarters,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfQuarter,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Last 3 Quarters as of prior year',
    duration: 3,
    durationType: DurationType.Quarter,
    expression: '0 0 0 1 */3 ? *',
    periodType: PeriodType.Quarterly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusYears,
      },
      {
        arguments: ['3'],
        function: TemporalAdjusterFunction.minusQuarters,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfQuarter,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Current YTD',
    duration: 1,
    durationType: DurationType.Year,
    expression: '0 0 0 1 1 ? *',
    periodType: PeriodType.Yearly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfYear,
      },
    ],
    type: TimespanType.Current_Period,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Last 1 Years',
    duration: 1,
    durationType: DurationType.Year,
    expression: '0 0 0 1 1 ? *',
    periodType: PeriodType.Yearly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusYears,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfYear,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location, Domain.Organization],
  },
  {
    id: '',
    name: 'Last 2 Years',
    duration: 1,
    durationType: DurationType.Year,
    expression: '0 0 0 1 1 ? *',
    periodType: PeriodType.Yearly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['2'],
        function: TemporalAdjusterFunction.minusYears,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfYear,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Organization],
  },
  {
    id: '',
    name: 'Last 3 Years',
    duration: 1,
    durationType: DurationType.Year,
    expression: '0 0 0 1 1 ? *',
    periodType: PeriodType.Yearly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['3'],
        function: TemporalAdjusterFunction.minusYears,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfYear,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Organization],
  },
  {
    id: '',
    name: 'Last 2 Years',
    duration: 2,
    durationType: DurationType.Year,
    expression: '0 0 0 1 1 ? *',
    periodType: PeriodType.Yearly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['2'],
        function: TemporalAdjusterFunction.minusYears,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfYear,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Last 3 Years',
    duration: 3,
    durationType: DurationType.Year,
    expression: '0 0 0 1 1 ? *',
    periodType: PeriodType.Yearly,
    temporalAdjusters: [
      {
        arguments: ['America/Los_Angeles'],
        function: TemporalAdjusterFunction.adjustTimezone,
      },
      {
        arguments: ['3'],
        function: TemporalAdjusterFunction.minusYears,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfYear,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Location],
  },
  {
    id: '',
    name: 'Last 1 T3M',
    duration: 1,
    durationType: DurationType.T3m,
    expression: '* * * ? * * *',
    periodType: PeriodType.T3m,
    temporalAdjusters: [
      {
        arguments: [],
        function: TemporalAdjusterFunction.maxAbsTimespan,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Organization],
  },
  {
    id: '',
    name: 'Last 1 T6M',
    duration: 1,
    durationType: DurationType.T6m,
    expression: '* * * ? * * *',
    periodType: PeriodType.T6m,
    temporalAdjusters: [
      {
        arguments: [],
        function: TemporalAdjusterFunction.maxAbsTimespan,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Organization],
  },
  {
    id: '',
    name: 'Last 1 TTM',
    duration: 1,
    durationType: DurationType.Ttm,
    expression: '* * * ? * * *',
    periodType: PeriodType.Ttm,
    temporalAdjusters: [
      {
        arguments: [],
        function: TemporalAdjusterFunction.maxAbsTimespan,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Organization],
  },
  {
    id: '',
    name: 'Last 1 TTM as of prior year',
    duration: 1,
    durationType: DurationType.Ttm,
    expression: '* * * ? * * *',
    periodType: PeriodType.Ttm,
    temporalAdjusters: [
      {
        arguments: [],
        function: TemporalAdjusterFunction.maxAbsTimespan,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusYears,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Organization],
  },
  {
    id: '',
    name: 'Last 1 TTM as of 2 years prior',
    duration: 1,
    durationType: DurationType.Ttm,
    expression: '* * * ? * * *',
    periodType: PeriodType.Ttm,
    temporalAdjusters: [
      {
        arguments: [],
        function: TemporalAdjusterFunction.maxAbsTimespan,
      },
      {
        arguments: ['2'],
        function: TemporalAdjusterFunction.minusYears,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Organization],
  },
  {
    id: '',
    name: 'YTD, Latest',
    duration: 1,
    durationType: DurationType.Ytd,
    expression: '* * * ? * * *',
    periodType: PeriodType.Ytd,
    temporalAdjusters: [
      {
        arguments: [],
        function: TemporalAdjusterFunction.maxAbsTimespan,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Organization],
  },
  {
    id: '',
    name: 'YTD as of prior Year',
    duration: 1,
    durationType: DurationType.Ytd,
    expression: '* * * ? * * *',
    periodType: PeriodType.Ytd,
    temporalAdjusters: [
      {
        arguments: [],
        function: TemporalAdjusterFunction.maxAbsTimespan,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusYears,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Organization],
  },
  {
    id: '',
    name: 'YTD as of two years prior',
    duration: 1,
    durationType: DurationType.Ytd,
    expression: '* * * ? * * *',
    periodType: PeriodType.Ytd,
    temporalAdjusters: [
      {
        arguments: [],
        function: TemporalAdjusterFunction.maxAbsTimespan,
      },
      {
        arguments: ['2'],
        function: TemporalAdjusterFunction.minusYears,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Organization],
  },
  {
    id: '',
    name: 'Last 12 Months',
    duration: 12,
    durationType: DurationType.Month,
    expression: '0 0 0 1 * ? *',
    periodType: PeriodType.Monthly,
    temporalAdjusters: [
      {
        arguments: [],
        function: TemporalAdjusterFunction.maxAbsTimespan,
      },
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusMonths,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfYear,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Organization],
  },
  {
    id: '',
    name: 'Performance year',
    duration: 12,
    durationType: DurationType.Pm,
    expression: '0 0 0 1 * ? *',
    periodType: PeriodType.Pm,
    temporalAdjusters: [
      {
        arguments: [],
        function: TemporalAdjusterFunction.maxAbsTimespan,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfPerfYear,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Organization],
  },
  {
    id: '',
    name: 'Last 15 Calendar Months',
    duration: 15,
    durationType: DurationType.Month,
    expression: '0 0 0 1 * ? *',
    periodType: PeriodType.Monthly,
    temporalAdjusters: [
      {
        arguments: [],
        function: TemporalAdjusterFunction.maxAbsTimespan,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfPerfYear,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfYear,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Organization],
  },
  {
    id: '',
    name: 'Last 15 Performance Months',
    duration: 15,
    durationType: DurationType.Pm,
    expression: '0 0 0 1 * ? *',
    periodType: PeriodType.Pm,
    temporalAdjusters: [
      {
        arguments: [],
        function: TemporalAdjusterFunction.maxAbsTimespan,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfPerfYear,
      },
      {
        arguments: [],
        function: TemporalAdjusterFunction.startOfYear,
      },
    ],
    type: TimespanType.Cron_Sequence,
    domains: [Domain.Organization],
  },
].map((timespan) => ({
  ...timespan,
  id: extractIdFromTimespan(timespan),
}));

export const timespanIdsToDisplayname = timespanConfigs.reduce<{
  [id: string]: string;
}>((acc, timespan) => {
  acc[timespan.id] = timespan.name;
  return acc;
}, {});

export const getTimespanFilters = (domain: string) => {
  return timespanConfigs.filter((timespanSpec) =>
    timespanSpec.domains.includes(domain),
  );
};
